import React from "react";
import DaraLogo from '../images/Dara-1.png';

function Header() {

  return (
    <header className="App-header sticky inset-x-0 top-0 z-50">
      <div className="flex justify-between items-center p-2 md:h-20 text-xs md:text-2xl font-serif underline tracking-wider">
        {/* Add home logo */}
        <div className="w-20 md:w-40 text-center flex justify-center">
          <a href="/"><img src={DaraLogo} alt="dara logo" /></a>
        </div>
        <a className="md:px-4" href="/">ABOUT</a>
        <a className="md:px-4" href="/portfolio">PORTFOLIO</a>
        <a className="md:px-4" href="/contact">CONTACT</a>
      </div>
    </header>
  )
}

export default Header