import React from "react";
import DataObjectIcon from '@mui/icons-material/DataObject';
import Clock from 'react-live-clock'
import PaceUni from "../images/Pace_University_Logo_2021.png"
import CumminsLogo from "../images/cummins-logo.png"
import { ReactComponent as ROIDNAPic } from "../images/logo-black-roi.svg"
import { ReactComponent as Google } from "../images/Google-G-Logo.svg"

function Body() {
    return (
        <div className="font-roboto">
            <Title />
            <Intro />
            <Education />
            <ROIDNA />
            <CUMMINS />
            <Certifications />
        </div>
    )
}

function Title() {
    let iconStyle = {
        width: "40px",
        height: "40px",
        opacity: "0.3",
        padding: "8px",
        borderRadius: "50%",
        backgroundColor: "#DDA15E"
    }
    return (
        <div className="flex justify-center items-center py-32 md:m-10">
            <div className="font-playfair text-4xl md:text-8xl lg:text-8xl xl:text-9xl">
                <div className="flex justify-between">
                    <h1>Data-Driven</h1>
                    <div className="text-xs md:text-base flex flex-col items-end opacity-50">
                        <div>MY LOCAL TIME:</div>
                        <Clock format={'hh:mm:ss A (z)'} ticking={true} timezone={'America/New_York'} />
                    </div>
                </div>
                <div className="flex">
                    <div className="p-2 md:mx-6 md:flex md:mt-4">
                        <DataObjectIcon style={iconStyle} />
                    </div>
                    <h1 style={{ color: "#606C38" }}><i>Marketing</i></h1><h1>&nbsp;Specialist</h1>
                </div>
            </div>
        </div>
    )
}

function Intro() {
    return (
        <div style={{ backgroundColor: "#BC6C25", color: "#FEFAE0", position: "relative" }} className="px-4 md:px-10 text-base md:text-2xl lg:text-4xl font-roboto py-32">
            <div className="md:p-20 lg:p-32">
                <div>
                    <h2 className="text-2xl md:text-4xl lg:text-6xl pb-8 md:pb-20">Hi! I'm Dara Adegunloye.</h2>
                    I'm a Marketing Specialist that's experienced with developing data-backed marketing collateral, case studies, partnerships, and
                    engaging social and video content with a high creative bar.
                </div>
            </div>
            <div className="daraBackground"></div>
        </div>
    )
}

function Education() {
    return (
        <div style={{ backgroundColor: "#FEFAE0", color: "#283618" }}
            className="px-4 md:px-10 text-base md:text-2xl lg:text-4xl font-roboto py-32 flex flex-col-reverse md:flex-row justify-center items-center">
            <div className="md:p-20 lg:p-32">
                <p>
                    I possess an MBA and will be graduating with a Masters of Science in Market Analytics from PACE University in New York City.
                </p>
            </div>
            <div className="pb-4 md:pb-0 w-2/3 md:w-full md:h-full md:pr-20 lg:pr-32 md:grayscale hover:grayscale-0">
                <img src={PaceUni} alt="" />
            </div>
        </div>
    )
}

function ROIDNA() {
    return (
        <div style={{ backgroundColor: "#DDA15E", color: "#283618" }} className="px-4 md:px-10 text-base md:text-2xl lg:text-4xl font-roboto py-32 flex flex-col md:flex-row justify-center items-center">
            <div className="pb-4 md:pb-0 w-2/3 md:w-full md:h-full md:pl-20 lg:pl-32 flex justify-center md:grayscale hover:grayscale-0">
                <ROIDNAPic style={{ width: "100%", height: "100%" }} />
            </div>
            <div className="md:p-20 lg:p-32">
                <p>
                    I currently work at ROI•DNA as an ABM Analyst Intern on the Customer Engagement Strategy Team where I created strategies that enabled desired client outcomes.
                </p>
            </div>
        </div>
    )
}

function CUMMINS() {
    return (
        <div style={{ backgroundColor: "#FEFAE0", color: "#283618" }}
            className="px-4 md:px-10 text-base md:text-2xl lg:text-4xl font-roboto py-32 flex flex-col-reverse md:flex-row justify-center items-center">
            <div className="md:p-20 lg:p-32">
                <p>
                    I previously worked at Cummins, Inc. as a Marketing Specialist Intern where I conducted market research and collaborated on GTM strategies.
                </p>
            </div>
            <div className="pb-4 md:pb-0 w-2/3 md:w-full md:h-full md:pr-20 lg:pr-32 md:grayscale hover:grayscale-0">
                <img alt="" style={{ filter: "invert(26%) sepia(63%) saturate(5760%) hue-rotate(352deg) brightness(88%) contrast(93%)" }} src={CumminsLogo} />
            </div>
        </div>
    )
}

function Certifications() {
    return (
        <div style={{ backgroundColor: "#BC6C25", color: "#FEFAE0", position: "relative" }} className="px-4 md:px-10 text-base md:text-2xl lg:text-4xl font-roboto py-32">
            <h2 className="md:pl-20 lg:pl-32 text-2xl md:text-4xl">Certifications:</h2>
            <div className="md:p-20 lg:p-32 flex flex-col flex-wrap md:flex-row justify-evenly items-center">
                <div className="w-fit">
                    <Google className="stroke-2 my-2" style={{ width: "200px", height: "180px" }} />
                    <p className="text-xs w-fit">Google Ads Display Fundamentals</p>
                </div>
                <a target="_blank" rel="noreferrer" href="https://skillsoft.digitalbadges.skillsoft.com/profile/ibidaraadegunloye557361/wallet">
                    <img className="lg:mx-8" src="https://images.credential.net/badge/tiny/vdj801gs_1660013884921_badge.png" alt="https://images.credential.net/badge/tiny/vdj801gs_1660013884921_badge.png" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://skillsoft.digitalbadges.skillsoft.com/profile/ibidaraadegunloye557361/wallet">
                    <img src="https://images.credential.net/badge/tiny/l42h9eli_1660705931236_badge.png" alt="https://images.credential.net/badge/tiny/l42h9eli_1660705931236_badge.png" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://skillsoft.digitalbadges.skillsoft.com/profile/ibidaraadegunloye557361/wallet">
                    <img className="lg:mx-8" src="https://images.credential.net/badge/tiny/qww7lw5x_1658420922171_badge.png" alt="https://images.credential.net/badge/tiny/qww7lw5x_1658420922171_badge.png" />
                </a>
                <div className="w-fit">
                    <Google className="stroke-2 my-2" style={{ width: "200px", height: "180px" }} />
                    <p className="text-xs w-fit">Google Ads Search Fundamentals</p>
                </div>
                <a target="_blank" rel="noreferrer" href="https://www.credly.com/badges/c103d5cc-e6f1-48fb-9a07-9ce8eef6e340/linked_in_profile">
                    <img className="mt-2 md:mt-0" style={{ width: "200px", height: "200px" }} src="https://images.credly.com/size/340x340/images/cef444e4-c584-4f7f-9738-fb908b38a88f/image.png" alt="certification" />
                </a>
            </div>
            <div className="daraBackground"></div>
        </div>
    )
}

export default Body