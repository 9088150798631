
import './App.css';
import React from 'react';
import Header from './components/header';
import BodyMain from './components/bodyMain';
import BodyContact from './components/bodyContact';
import Footer from './components/footer';
import { Route, Routes } from 'react-router-dom';
import BodyPortfolio from './components/bodyPortfolio';

function App() {
  const [loadedScript, setLoadedScript] = React.useState(false);
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<BodyMain/>} />
        <Route path="/portfolio" element={<BodyPortfolio/>} />
        <Route path="/contact" element={<BodyContact loadedScript={loadedScript} setLoadedScript={setLoadedScript}/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
