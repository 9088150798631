import React from "react";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Instagram from "@mui/icons-material/Instagram";
import { ReactComponent as TikTok } from '../images/tiktok.svg';

function Footer() {
    return (
        <footer className="App-footer py-32 md:px-10">
            <div className="text-2xl p-6 md:text-7xl lg:text-9xl md:p-16 lg:p-28 text-center">
                <div>DISCOVER</div>
                <div>YOUR DATA-DRIVEN</div>
                <div>STRATEGY</div>
            </div>
            <div className="flex justify-center items-center">
                <div>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/daraade"><LinkedIn style={{ width: "50px", height: "50px" }} /></a>
                </div>
                <div>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/dara_ade/"><Instagram style={{ width: "50px", height: "50px" }} /></a>
                </div>
                <div className="mx-1">
                    <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@dailydoseofdara"><TikTok style={{ width: "42px", height: "42px", filter: "brightness(0) saturate(100%) invert(91%) sepia(8%) saturate(503%) hue-rotate(3deg) brightness(106%) contrast(99%)" }} /></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer