import React, { useEffect } from "react";

function BodyContact() {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://platform.linkedin.com/badges/js/profile.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    })

    return (
        <div>
            <div className="flex justify-center items-center text-center" style={{ height: "76vh" }}>
                <div className="badge-base LI-profile-badge" data-locale="en_US" data-size="large" data-theme="light" data-type="HORIZONTAL" data-vanity="daraade" data-version="v1"><a className="badge-base__link LI-simple-link" href="https://www.linkedin.com/in/daraade?trk=profile-badge">Dara Adegunloye, MBA</a></div>
            </div>
        </div>
    )
}

export default BodyContact